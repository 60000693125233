// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'user',
    label: '',
    Object: 'value',
    width: '120'
  }, {
    prop: 'order_detail',
    label: '',
    Object: 'value',
    width: '260'
  }, {
    prop: 'contact_info',
    label: '',
    Object: 'value',
    width: '130'
  }, {
    prop: 'status',
    label: '',
    Object: 'value',
    width: '130'
  }],
  orderViewItem: [{
    prop: 'status',
    back: true
  }, {
    prop: 'type'
  }, {
    prop: 'created_at',
    date: true,
    format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }, {
    prop: 'remarks'
  }, {
    prop: 'shop'
  }, {
    prop: 'payment_channel'
  }, {
    prop: 'paid_at',
    date: true,
    format: ['YYYY年mm月dd日 HH:MM', 'YYYY-mm-dd HH:MM']
  }],
  CustomerViewItem: [{
    prop: 'customer_name'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'customer_id'
  }, {
    prop: 'nickname'
  }, {
    prop: 'email'
  }],
  deliveryViewItem: [{
    prop: 'delivery_time'
  }, {
    prop: 'receiver_name'
  }, {
    prop: 'detail_address'
  }, {
    prop: 'receiver_phone_number'
  }],
  collectionViewItem: [{
    prop: 'collection_time'
  }, {
    prop: 'receiver_name'
  }, {
    prop: 'detail_address'
  }, {
    prop: 'receiver_phone_number'
  }],
  tableDataViewItem: [{
    prop: 'image',
    img: true,
    label: '',
    width: '50'
  }, {
    prop: 'shop_item',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'shop_item_stock',
    label: '',
    Object: 'value'
    // width: '100'
  }, {
    prop: 'preferences',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'sku',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'count',
    label: '',
    // width: '40',
    Object: 'value'
  }, {
    prop: 'price',
    label: '',
    Object: 'value'
    // width: '40'
  }, {
    prop: 'status',
    back: true,
    label: '',
    Object: 'value',
    width: '40'
  }]
}
